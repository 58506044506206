/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

.notification-success, .notification-error {
  background-color: green;
  color: #fff;
}
.notification-error {
  background-color:red;
}

.mat-spinner {
  margin :0 auto;
}

/* body {
  background-color: rgb(163 203 214);
} */